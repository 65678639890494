const InitData = [
  {
    id: 1179270258,
    label: "Disclose.tv",
    msg: "Disclose.tvdisclosetv",
    telegram_username: "disclosetv",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@disclosetv",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1342489186,
    label: "gov.sg",
    msg: "gov.sgGovsg",
    telegram_username: "Govsg",
    image: "https://core-api.privateai.com/social/telegram/profilePhoto/@Govsg",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1430871715,
    label: "The Babylon Bee",
    msg: "The Babylon BeeTheBabylonBee",
    telegram_username: "TheBabylonBee",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@TheBabylonBee",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1200497997,
    label: "Project Veritas",
    msg: "Project Veritasproject_veritas",
    telegram_username: "project_veritas",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@project_veritas",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1384760992,
    label: "🌀 Vertigo World News",
    msg: "🌀 Vertigo World NewsNone",
    telegram_username: null,
    image: "https://core-api.privateai.com/social/telegram/profilePhoto/@None",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1606432449,
    label: "The New York Times",
    msg: "The New York Timesnytimes",
    telegram_username: "nytimes",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@nytimes",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1235322843,
    label: "GEORGENEWS",
    msg: "GEORGENEWSgeorgenews",
    telegram_username: "georgenews",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@georgenews",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1392160803,
    label: "Breaking911",
    msg: "Breaking911breaking911",
    telegram_username: "breaking911",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@breaking911",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1161789591,
    label: "We The Media",
    msg: "We The MediaWeTheMedia",
    telegram_username: "WeTheMedia",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@WeTheMedia",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1326663868,
    label: "Hananya Naftali - Israel News",
    msg: "Hananya Naftali - Israel Newshnaftali",
    telegram_username: "hnaftali",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@hnaftali",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1190116618,
    label: "One America News Network",
    msg: "One America News NetworkOANNTV",
    telegram_username: "OANNTV",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@OANNTV",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1408963933,
    label: "Gateway Pundit",
    msg: "Gateway Punditgatewaypunditofficial",
    telegram_username: "gatewaypunditofficial",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@gatewaypunditofficial",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1387645188,
    label: "RT News",
    msg: "RT Newsrtnews",
    telegram_username: "rtnews",
    size: 20,
    color: { border: "#ff0000" },
  },
  {
    id: 1625429257,
    label: "War Monitor",
    msg: "War Monitorwarmonitors",
    telegram_username: "warmonitors",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@warmonitors",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1479163323,
    label: "Tommy Robinson News",
    msg: "Tommy Robinson NewsTommyRobinsonNews",
    telegram_username: "TommyRobinsonNews",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@TommyRobinsonNews",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 2161703841,
    label: "$DJT Portal",
    msg: "$DJT PortalDJTtoken",
    telegram_username: "DJTtoken",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@DJTtoken",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 2039438207,
    label: "Blum: All Crypto – One App",
    msg: "Blum: All Crypto – One Appblumcrypto",
    telegram_username: "blumcrypto",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@blumcrypto",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1769001896,
    label: "Coingraph | News",
    msg: "Coingraph | NewsNone",
    telegram_username: null,
    image: "https://core-api.privateai.com/social/telegram/profilePhoto/@None",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1892497462,
    label: "@crypto",
    msg: "@cryptoNone",
    telegram_username: null,
    image: "https://core-api.privateai.com/social/telegram/profilePhoto/@None",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1607709756,
    label: "КриптоБош",
    msg: "КриптоБошcryptobosh",
    telegram_username: "cryptobosh",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@cryptobosh",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1119474689,
    label: "Cryptoy ₿",
    msg: "Cryptoy ₿Cryptoy",
    telegram_username: "Cryptoy",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@Cryptoy",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1577681416,
    label: "CryptoHamster🐹 - news & drops",
    msg: "CryptoHamster🐹 - news & dropshamsterdrops",
    telegram_username: "hamsterdrops",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@hamsterdrops",
    size: 20,
    color: { border: "#ff0000" },
    shape: "circularImage",
  },
  {
    id: 1150424885,
    label: "Patri0tsareinContr0l ️️️",
    telegram_username: "Patri0tsareinContr0l",
    msg: "This paragraph says it all.\n\nKamala voters believe the economy is good, border crossings are down, and widespread voter fraud doesn’t exist.\n\nTrump voters believe the economy is bad, border crossings are up, and widespread voter fraud is real.\n\nBrainwashed vs unbrainwashed.\n\nhttps://x.com/patri0tcontr0l/status/1845485170129830369?s=46&t=yH_YwZotlm6m-dfwNvYx3QPatri0tsareinContr0l ️️️Patri0tsareinContr0l",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@Patri0tsareinContr0l",
    size: 20.666666666666668,
    shape: "circularImage",
  },
  {
    id: 1758820844,
    label: "Dot Connecting Anons",
    telegram_username: "dotconnectinganons",
    msg: "💥Former RNC chair goes on ABC and says flatly:\n\n“The reality is that for the first time in 32 years in this poll it shows that REPUBLICANS ARE BEATING DEMOCRATS STRAIGHT UP.\n\n“When you ask people on a generic ballot - Donald Trump is doing better with black voters, Hispanic voters, off the charts! \n\n“But what the most important thing is, what happened last week is that what was working with female voters with Kamala Harris was that there was some feeling of JOY in the air. Well, THAT JOY IS GONE. She had a terrible week. She picked a pointless fight with Ron DeSantis, she’s losing in the Sunbelt. And she tried to recover by going on The View, which a lot of women watch. \n\n“She went on the view and she wanted to clarify the two most important issues facing these two candidates- the economy and immigration. And she BOMBED… and said there was NOTHING she would do that would be different than Joe Biden. She created a campaign commercial for Donald Trump.\n\n“Everybody knows it and it’s obvious that there is a LEAK in the balloon here in the Kamala Harris campaign!” 🔥🔥\n\nXPOSTDot Connecting Anonsdotconnectinganons",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@dotconnectinganons",
    size: 20.666666666666668,
    shape: "circularImage",
  },
  {
    id: 1273771484,
    label: "BrittRepublican",
    telegram_username: "BrittRepublican",
    msg: "BrittRepublicanBrittRepublican",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@BrittRepublican",
    size: 20.666666666666668,
    shape: "circularImage",
  },
  {
    id: 1410273150,
    label: "TheStormHasArrived17",
    telegram_username: "TheStormHasArrived17",
    msg: "President Trump reveals that he spoke with Bibi Netanyahu just 2 days ago and that Netanyahu is not listening to Biden 👀\n\nWho is really running things?TheStormHasArrived17TheStormHasArrived17",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@TheStormHasArrived17",
    size: 20.666666666666668,
    shape: "circularImage",
  },
  {
    id: 1701107630,
    label: "Nevs",
    telegram_username: "NevsChannel",
    msg: "I don't trust the polls at all, but MSM melting down at them is entertaining 🍿\n\nrepost on XNevsNevsChannel",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@NevsChannel",
    size: 20.666666666666668,
    shape: "circularImage",
  },
  {
    id: 1245206509,
    label: "Justin Deschamps",
    telegram_username: "justindeschamps",
    msg: "Trump going on Joe Rogan!\n___\n\nDonald Trump confirms he’s going on with Joe Rogan.\n\nBoom.\n\nhttps://x.com/charliekirk11/status/1845300148613808267?s=46Justin Deschampsjustindeschamps",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@justindeschamps",
    size: 20.960164358359172,
    shape: "circularImage",
  },
  {
    id: 1550381148,
    label: "Jon Herold",
    telegram_username: "patelpatriot",
    msg: "Don't miss the #1 Saturday show on @rumblevideo \n\nTonight we'll discuss:\n\n- Trump & Wartime Powers\n- Is Kamala Retarded?\n- Weather destroying marriages\n- Yard signs\n\nand More!\n\nSee you at 10:30 PM ET\n\nhttps://rumble.com/v5ie3ol-devolution-power-hour-292-1030-pm-et-.html?mref=qxa4n&mc=51udwJon Heroldpatelpatriot",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@patelpatriot",
    size: 20.666666666666668,
    shape: "circularImage",
  },
  {
    id: 1760394580,
    label: "Vigilant News",
    telegram_username: "vigilantnews",
    msg: "WOW!!!!! Check out the view from above at tonight’s #TrumpRally in beautiful Coachella, California! #TRUMP2024\n\nhttps://x.com/danscavino/status/1845272215803265281?s=46Vigilant Newsvigilantnews",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@vigilantnews",
    size: 20.666666666666668,
    shape: "circularImage",
  },
  {
    id: 1391253363,
    label: "Midnight Rider Channel 🇺🇸",
    telegram_username: "realKarliBonne",
    msg: "Midnight Rider Channel 🇺🇸realKarliBonne",
    image:
      "https://core-api.privateai.com/social/telegram/profilePhoto/@realKarliBonne",
    size: 20.666666666666668,
    shape: "circularImage",
  },
];

export { InitData };
