import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../providers/AppProvider";
import { HypeModal } from "../HypeModal";
import Modal from "react-modal";
import { ModalOptions } from "../../constants";

Modal.setAppElement("#root");

const SearchForm = ({ data, clearNodes, reset }) => {
  const { onChange, searchInput, setMainNode, mainNode, modalIsOpen } =
    useContext(AppContext);
  const [, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  const [_data, setData] = useState([...data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setData([...data]);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [data]);

  const handleSearchElementClick = (data_item) => {
    const { label, id } = data_item;
    handleInputOnchange({ msg: label });
    clearNodes([id]);
    setMainNode([id]);
  };

  const handleInputOnchange = ({ e, msg }) => {
    onChange(e?.target.value || msg);
    setShowAll(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const getHighlightedText = (text, highlight) => {
    if (!highlight) return text;

    const regex = new RegExp(
      `(?:\\b\\w+\\b\\s*){0,5}\\w*(${highlight})\\w*(\\s*\\b\\w+\\b){0,5}`,
      "gi"
    );
    const matches = [...text.matchAll(regex)];

    if (matches.length > 0) {
      return matches.map((match, index) => {
        const [fullMatch] = match;

        const parts = fullMatch.split(new RegExp(`(${highlight})`, "gi"));

        return (
          <span key={index}>
            {parts.map((part, subIndex) =>
              part.toLowerCase().includes(highlight.toLowerCase()) ? (
                <span key={subIndex} className="highlight">
                  {part}
                </span>
              ) : (
                part
              )
            )}
            {index < matches.length - 1 ? "..." : ""}{" "}
          </span>
        );
      });
    }
    return text;
  };

  const listData = searchInput
    ? _data.filter(
        (data_item) =>
          data_item.msg &&
          searchInput &&
          data_item.msg.toLowerCase().includes(searchInput.toLowerCase())
      )
    : _data;

  // const slicedListData = isShowAll ? listData : listData.slice(0, 5);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        modalRef.current &&
        (!modalRef.current.contains(event.target) ||
          !event.target.closest(".form"))
        // ||
        // !event.target.querySelector(".form")
      ) {
        setShowAll(false);
        closeModal();
      }
    };

    if (showModal) {
      document.addEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [showModal]);

  const showAllSearchResults = (list) => {
    setMainNode(list.map((item) => item.id));
    clearNodes(list.map((item) => item.id));
  };

  return (
    <>
      {showModal && <div className="modal_overlay"></div>}
      <form onSubmit={onSubmit} ref={modalRef} className="form">
        <div
          className=""
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              flexWrap: "wrap",
            }}
          >
            <label>Find channels that mentioned: </label>
            <div
              className=""
              style={{
                position: "relative",
              }}
            >
              <input
                type="text"
                value={searchInput || ""}
                onChange={(e) => {
                  handleInputOnchange({ e });
                }}
                onClick={() => {
                  setShowModal(true);
                }}
                placeholder="Ticker"
              />
              {(searchInput || mainNode) && (
                <span
                  className="clear"
                  onClick={() => {
                    setMainNode(null);
                    handleInputOnchange({ msg: "" });
                    setShowModal(false);
                    // if (mainNode) {
                    clearNodes();
                    reset();
                    // }
                  }}
                >
                  Clear
                </span>
              )}
            </div>
          </div>
          {showModal && (
            <div className="dropdown">
              <ul>
                {searchInput && listData.length > 1 && (
                  <li>
                    <div
                      className="button functionl"
                      onClick={() => {
                        setShowModal(false);
                        showAllSearchResults(listData);
                      }}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Show all results as a graph
                    </div>
                  </li>
                )}
                {listData.map(
                  (data_item) =>
                    data_item.label && (
                      <li key={data_item.id}>
                        <div
                          className="button"
                          onClick={() => {
                            handleSearchElementClick(data_item);
                            closeModal();
                          }}
                        >
                          {data_item.label} {data_item.msg}
                          {/* {getHighlightedText(data_item.label, searchInput)}{" "} */}
                          {/* {getHighlightedText(data_item.msg, searchInput)} */}
                        </div>
                      </li>
                    )
                )}
                {/* {!isShowAll && listData.length > 5 && (
                  <li>
                    <div
                      className="button"
                      onClick={handleShowAllClick}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Show all
                    </div>
                  </li>
                )} */}
                {listData.length === 0 && searchInput && (
                  <li
                    style={{
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    No data
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={ModalOptions}
        contentLabel="Example Modal"
      >
        <HypeModal clearNodes={clearNodes} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export { SearchForm };
