import { useContext } from "react";
import { HypeChart } from "../../components/HypeChart";
import { AppContext } from "../../providers/AppProvider";

const Home = () => {
  const { searchInput, openModal } = useContext(AppContext);
  return (
    <>
      <HypeChart searchInput={searchInput} openModal={openModal} />
    </>
  );
};

export { Home };
