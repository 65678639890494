const EdgesData = [
  {
    id: "11617895911150424885",
    from: 1150424885,
    to: 1161789591,
    color: "#ffffff",
  },
  {
    id: "11617895911245206509",
    from: 1245206509,
    to: 1161789591,
    color: "#ffffff",
  },
  {
    id: "11617895911758820844",
    from: 1758820844,
    to: 1161789591,
    color: "#ffffff",
  },
  {
    id: "11617895911273771484",
    from: 1273771484,
    to: 1161789591,
    color: "#ffffff",
  },
  {
    id: "11617895911179270258",
    from: 1179270258,
    to: 1161789591,
    color: "#ffffff",
  },
  {
    id: "11617895911410273150",
    from: 1410273150,
    to: 1161789591,
    color: "#ffffff",
  },
  {
    id: "11617895911701107630",
    from: 1701107630,
    to: 1161789591,
    color: "#ffffff",
  },
  {
    id: "11617895911550381148",
    from: 1550381148,
    to: 1161789591,
    color: "#ffffff",
  },
  {
    id: "11617895911760394580",
    from: 1760394580,
    to: 1161789591,
    color: "#ffffff",
  },
  {
    id: "14089639331391253363",
    from: 1391253363,
    to: 1408963933,
    color: "#ffffff",
  },
  {
    id: "17588208441150424885",
    from: 1150424885,
    to: 1758820844,
    color: "#ffffff",
  },
  {
    id: "17588208441179270258",
    from: 1179270258,
    to: 1758820844,
    color: "#ffffff",
  },
  {
    id: "17588208441273771484",
    from: 1273771484,
    to: 1758820844,
    color: "#ffffff",
  },
  {
    id: "17588208441410273150",
    from: 1410273150,
    to: 1758820844,
    color: "#ffffff",
  },
  {
    id: "17588208441701107630",
    from: 1701107630,
    to: 1758820844,
    color: "#ffffff",
  },
  {
    id: "17588208441778171385",
    from: 1778171385,
    to: 1758820844,
    color: "#ffffff",
  },
  {
    id: "17588208441703373052",
    from: 1703373052,
    to: 1758820844,
    color: "#ffffff",
  },
  {
    id: "17588208441760394580",
    from: 1760394580,
    to: 1758820844,
    color: "#ffffff",
  },
  {
    id: "17588208441161789591",
    from: 1161789591,
    to: 1758820844,
    color: "#ffffff",
  },
  {
    id: "12737714841485236117",
    from: 1485236117,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841474240479",
    from: 1474240479,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841150424885",
    from: 1150424885,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841758820844",
    from: 1758820844,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841161789591",
    from: 1161789591,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841179270258",
    from: 1179270258,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841391253363",
    from: 1391253363,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841412053777",
    from: 1412053777,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841522046510",
    from: 1522046510,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841760394580",
    from: 1760394580,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "12737714841778171385",
    from: 1778171385,
    to: 1273771484,
    color: "#ffffff",
  },
  {
    id: "17011076301391253363",
    from: 1391253363,
    to: 1701107630,
    color: "#ffffff",
  },
  {
    id: "15503811481595587992",
    from: 1595587992,
    to: 1550381148,
    color: "#ffffff",
  },
  {
    id: "17603945801245206509",
    from: 1245206509,
    to: 1760394580,
    color: "#ffffff",
  },
  {
    id: "17603945802110923466",
    from: 2110923466,
    to: 1760394580,
    color: "#ffffff",
  },
  {
    id: "13912533631408963933",
    from: 1408963933,
    to: 1391253363,
    color: "#ffffff",
  },
  {
    id: "17781713851697218586",
    from: 1697218586,
    to: 1778171385,
    color: "#ffffff",
  },
  {
    id: "17781713851179270258",
    from: 1179270258,
    to: 1778171385,
    color: "#ffffff",
  },
  {
    id: "14852361171444443883",
    from: 1444443883,
    to: 1485236117,
    color: "#ffffff",
  },
  {
    id: "14852361171703373052",
    from: 1703373052,
    to: 1485236117,
    color: "#ffffff",
  },
  {
    id: "14852361171358907673",
    from: 1358907673,
    to: 1485236117,
    color: "#ffffff",
  },
  {
    id: "14852361171179270258",
    from: 1179270258,
    to: 1485236117,
    color: "#ffffff",
  },
  {
    id: "14742404791283151103",
    from: 1283151103,
    to: 1474240479,
    color: "#ffffff",
  },
  {
    id: "14742404791273771484",
    from: 1273771484,
    to: 1474240479,
    color: "#ffffff",
  },
  {
    id: "14742404791245206509",
    from: 1245206509,
    to: 1474240479,
    color: "#ffffff",
  },
  {
    id: "14742404791136479756",
    from: 1136479756,
    to: 1474240479,
    color: "#ffffff",
  },
  {
    id: "14742404791539710436",
    from: 1539710436,
    to: 1474240479,
    color: "#ffffff",
  },
  {
    id: "14120537771329886149",
    from: 1329886149,
    to: 1412053777,
    color: "#ffffff",
  },
  {
    id: "15955879921150424885",
    from: 1150424885,
    to: 1595587992,
    color: "#ffffff",
  },
  {
    id: "14444438831341028511",
    from: 1341028511,
    to: 1444443883,
    color: "#ffffff",
  },
  {
    id: "11364797561327073191",
    from: 1327073191,
    to: 1136479756,
    color: "#ffffff",
  },
  {
    id: "15397104361523723904",
    from: 1523723904,
    to: 1539710436,
    color: "#ffffff",
  },
];

export { EdgesData };
